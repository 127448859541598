import React, { useEffect } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { Button } from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';

import { ButtonBlock, JustifyCenter } from 'style';
import { Input, InputPhone, MarginTop, Typography } from 'components';
import { PaymentOption } from 'models/payment.model';
import { PaymentNetwork } from 'enum/enum';

import { schema } from './schema';
import { InputImageAdornment } from './styles';

interface FormValues {
  phoneNumber: string;
}

type PropTypes = {
  onSubmitHandler: () => void;
  accountNumber: string;
  updateAccountNumber: (accountNumber: string) => void;
  disabled: boolean;
  activePaymentTab: PaymentOption;
};

export const MobilePaymentForm = ({ onSubmitHandler, updateAccountNumber, disabled, activePaymentTab, accountNumber }: PropTypes) => {
  const { network, img, countryCodeName } = activePaymentTab;
  const isVoucher = network === PaymentNetwork.VOUCHER;

  const {
    handleSubmit,
    setValue,
    formState: { errors },
    control,
  } = useForm<FormValues>({
    resolver: yupResolver(schema(network)),
  });

  const phoneNumber = useWatch({
    control,
    name: 'phoneNumber',
    defaultValue: accountNumber,
  });

  useEffect(() => {
    setValue('phoneNumber', accountNumber);
  }, [accountNumber]);

  useEffect(() => {
    updateAccountNumber(phoneNumber);
  }, [phoneNumber]);

  const onSubmit = () => {
    onSubmitHandler();
  };

  return (
    <MarginTop marginTop="30">
      <form onSubmit={handleSubmit(onSubmit)} autoComplete={'off'}>
        <Typography variant="body">Numberka aad ka so diray so</Typography>
        <MarginTop marginTop="10">
          {isVoucher ? (
            <Input
              label="Voucher"
              control={control}
              name="phoneNumber"
              error={errors?.phoneNumber}
              InputProps={{
                endAdornment: <InputImageAdornment src={img} alt="payment" />,
              }}
            />
          ) : (
            <InputPhone
              defaultCountry={countryCodeName}
              label="Phone Number"
              error={errors?.phoneNumber}
              control={control}
              name="phoneNumber"
              InputProps={{
                endAdornment: <InputImageAdornment src={img} alt="payment" />,
              }}
            />
          )}
        </MarginTop>
        <ButtonBlock>
          <JustifyCenter>
            <Button type="submit" variant="contained" size="large" fullWidth disabled={disabled}>
              SEND CODE
            </Button>
          </JustifyCenter>
        </ButtonBlock>
      </form>
    </MarginTop>
  );
};
