import { Currency, PaymentMode, PaymentNetwork, PaymentNetworkTitle, PaymentStatusEnum } from 'enum/enum';
import { isDauusDomain } from 'config/domain/features';
import { images } from 'constants/images';

export const isMpesaAvailable = JSON.parse(process.env.REACT_APP_MPESA_PAYMENT_AVAILABLE ?? 'false');

const isVoucher = isDauusDomain();
const {
  mPressaExpress,
  mpesa,
  creditCards,
  zaad,
  golis,
  voucher,
  waafi,
  jcb,
  discover,
  dinersclub,
  amex,
  stripe,
  visa,
  unionpay,
  mastercard,
  edahab,
} = images.payments;

export const payments = [
  {
    network: PaymentNetwork.EVC_PLUS,
    title: PaymentNetworkTitle.EVC_PLUS,
    img: images.payments.evcplus,
    paymentMode: PaymentMode.WAAFI,
    countryCodeName: 'so',
  },
  { network: PaymentNetwork.ZAAD, title: PaymentNetworkTitle.ZAAD, img: zaad, paymentMode: PaymentMode.WAAFI, countryCodeName: 'so' },
  { network: PaymentNetwork.SAHAL, title: PaymentNetworkTitle.SAHAL, img: golis, paymentMode: PaymentMode.WAAFI, countryCodeName: 'so' },
  ...(isMpesaAvailable
    ? [
        {
          network: PaymentNetwork.M_PESA_EXPRESS,
          title: PaymentNetworkTitle.M_PESA_EXPRESS,
          img: mPressaExpress,
          paymentMode: PaymentMode.MPESA_EXPRESS,
          countryCodeName: 'ke',
        },
      ]
    : []),
  {
    network: PaymentNetwork.CREDIT_CARD,
    title: PaymentNetworkTitle.CREDIT_CARD,
    img: creditCards,
    paymentMode: PaymentMode.STRIPE,
    countryCodeName: 'so',
  },
  ...(isVoucher
    ? [
        {
          network: PaymentNetwork.VOUCHER,
          title: PaymentNetworkTitle.VOUCHER,
          img: voucher,
          paymentMode: PaymentMode.VOUCHER,
          countryCodeName: 'so',
        },
      ]
    : []),
];

export const currencyOptions = [
  { title: Currency.USD, value: Currency.USD },
  { title: Currency.KES, value: Currency.KES },
];
export const getCardTypes = (isVoucher: boolean) => {
  const cardTypes = [
    { cardBrand: 'waafi', img: waafi },
    { cardBrand: 'mpesa', img: mpesa },
    { cardBrand: 'visa', img: visa },
    { cardBrand: 'Edahab', img: edahab },
    { cardBrand: 'mastercard', img: mastercard },
    { cardBrand: 'amex', img: amex },
    { cardBrand: 'discover', img: discover },
    { cardBrand: 'unionpay', img: unionpay },
    { cardBrand: 'jcb', img: jcb },
    { cardBrand: 'stripe', img: stripe },
    { cardBrand: 'dinersclub', img: dinersclub },
  ];

  if (isVoucher) {
    cardTypes.push({ cardBrand: 'voucher', img: voucher });
  }
  return cardTypes;
};

export const successfulPaymentStatuses = [
  PaymentStatusEnum.SUCCEEDED,
  PaymentStatusEnum.AUTHORIZED,
  PaymentStatusEnum.PAID,
  PaymentStatusEnum.COMPLETE,
];

export const isSuccessPaymentStatus = (status: PaymentStatusEnum | null) => status === PaymentStatusEnum.PAID;

export const failedPaymentStatuses = [PaymentStatusEnum.CANCELLED, PaymentStatusEnum.FAILED, PaymentStatusEnum.ERROR];

export const pendingPaymentStatuses = [PaymentStatusEnum.NOT_PAID, PaymentStatusEnum.OPEN];
