import React from 'react';
import FlightTakeoffIcon from '@mui/icons-material/FlightTakeoff';
import WorkTwoToneIcon from '@mui/icons-material/WorkTwoTone';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import InfoIcon from '@mui/icons-material/Info';
import PrivacyTipIcon from '@mui/icons-material/PrivacyTip';
import NewspaperIcon from '@mui/icons-material/Newspaper';
import GroupsIcon from '@mui/icons-material/Groups';
import { routes } from 'routes';

export const drawerSections = [
  {
    title: 'Search',
    items: [{ sectionTitle: 'Start New Search', icon: <FlightTakeoffIcon />, route: routes.home }],
  },
  {
    title: 'Plan your travel',
    items: [{ sectionTitle: 'My trips', icon: <WorkTwoToneIcon />, route: routes.mytrips }],
  },
  {
    title: 'Company',
    items: [
      { sectionTitle: 'Affiliate Program', icon: <GroupsIcon />, route: routes.affiliate },
      { sectionTitle: 'Help/FAQ', icon: <HelpOutlineIcon />, route: routes.help },
      { sectionTitle: 'About', icon: <InfoIcon />, route: routes.about },
      { sectionTitle: 'Privacy & Terms', icon: <PrivacyTipIcon />, route: routes.privacyTerms },
      { sectionTitle: 'Blog', icon: <NewspaperIcon />, route: routes.blog },
    ],
  },
];
