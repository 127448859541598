import { Box, Button, styled } from '@mui/material';

export const TabsWrapper = styled(Box)`
  display: flex;
  width: 100%;
  border-radius: 6px;
  margin-bottom: 30px;
`;
export const ButtonTab = styled(Button)<{ active: boolean }>`
  padding: 12px;
  width: 100%;
  color: ${(props) => (props.active ? 'var(--accent)' : 'var(--primary)')};
  background-color: ${(props) => (props.active ? 'var(--primary)' : 'var(--white)')};
  border-radius: 0;
  text-transform: none;
  min-height: max-content;
  border-top: 2px solid var(--primary);
  border-bottom: 2px solid var(--primary);

  &:hover {
    background-color: ${(props) => (props.active ? 'var(--primary)' : '')};
  }

  &:first-of-type {
    border-left: 2px solid var(--primary);
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }

  &:last-of-type {
    border-right: 2px solid var(--primary);
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }
`;
