import { useAppSelector } from 'hooks/useAppSelector';
import { successfulPaymentStatuses } from 'constants/payments';
import { PaymentMode, PaymentStatusEnum } from 'enum/enum';
import { getConfirmationNumber, getPaymentCard } from 'utils/payment';
import { AssociatedPaymentModel } from 'models/associatedPayment.model';
import { getAmountCurrency } from 'utils/currency';

export const useGetPaymentInfo = () => {
  const { associatedPayments } = useAppSelector((state) => state.flightOrder);

  const paymentInfo = associatedPayments?.find((payment) =>
    successfulPaymentStatuses.includes(payment.paymentState as PaymentStatusEnum),
  ) as AssociatedPaymentModel;

  const card = getPaymentCard(paymentInfo);
  const confirmationNumber = getConfirmationNumber(paymentInfo);
  const price = getAmountCurrency(paymentInfo?.paymentValue);
  const isMpesa = paymentInfo?.paymentMode === PaymentMode.MPESA_EXPRESS;

  return {
    cardLast4: paymentInfo?.cardLast4 || '-',
    lastCardDigitsLabel: isMpesa ? 'Last 3 Digits' : 'Last 4 Digits',
    price,
    card,
    confirmationNumber,
    paymentMode: paymentInfo?.paymentMode as PaymentMode,
  };
};
