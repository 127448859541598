import React from 'react';
import { Control, Controller, FieldError } from 'react-hook-form';
import { ParsedCountry } from 'react-international-phone/dist/types';
import { defaultCountries, FlagImage, usePhoneInput } from 'react-international-phone';
import { FormControl, InputAdornment } from '@mui/material';
import 'react-international-phone/style.css';
import { TextFieldProps } from '@mui/material/TextField/TextField';
import { Input } from './styles';

interface OnChangeData {
  phone: string;
  inputValue: string;
  country: ParsedCountry;
}

interface Props {
  name: string;
  label: string;
  control: Control<any>;
  error?: FieldError;
  defaultCountry?: string;
}

type RestrictedDefaultProps = Omit<TextFieldProps, 'name' | 'label' | 'error' | 'onChange' | 'value'>;
type FullProps = Props & RestrictedDefaultProps;

interface MuiPhoneType extends Omit<FullProps, 'control'> {
  onChange: (v: string) => void;
  value: string;
}

export const InputPhone = ({ control, label, name, error, ...props }: FullProps) => {
  return (
    <FormControl fullWidth error={!!error}>
      <Controller
        name={name}
        control={control}
        render={({ field }) => {
          return <MuiPhone {...field} label={label} {...props} error={error} />;
        }}
      />
    </FormControl>
  );
};

const MuiPhone = ({ value, onChange, label, error, InputProps, defaultCountry = 'so', ...restProps }: MuiPhoneType) => {
  const { inputValue, handlePhoneValueChange, inputRef, country } = usePhoneInput({
    defaultCountry: defaultCountry,
    value,
    countries: defaultCountries,
    onChange: (data: OnChangeData) => {
      onChange?.(data.phone as string);
    },
  });

  const mergedInputProps = {
    startAdornment: (
      <InputAdornment position="start">
        <FlagImage iso2={country.iso2} style={{ marginRight: '8px', width: '24px', height: '24px' }} />
      </InputAdornment>
    ),
    ...InputProps,
  };

  return (
    <Input
      variant="outlined"
      label={label}
      color="primary"
      placeholder="Phone number"
      value={inputValue}
      onChange={handlePhoneValueChange}
      type="tel"
      error={!!error}
      inputRef={inputRef}
      InputProps={mergedInputProps}
      helperText={error?.message}
      {...restProps}
    />
  );
};
