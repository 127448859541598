import React from 'react';
import Typography from '@mui/material/Typography';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AccordionDetails from '@mui/material/AccordionDetails';

interface Props {
  items: Array<{
    title: string;
    subtitle: React.ReactNode;
  }>;
  title?: string;
  defaultExpanded?: boolean;
}

export const Accordions = ({ title, items, defaultExpanded }: Props) => {
  return (
    <div>
      {title && (
        <div style={{ marginBottom: '16px' }}>
          <Typography fontWeight={700} variant={'h5'}>
            {title}
          </Typography>
        </div>
      )}
      <div>
        {items.map((item, i) => (
          <Accordion
            key={i}
            defaultExpanded={defaultExpanded}
            disableGutters
            style={{ marginBottom: '16px', padding: '8px' }}
            sx={{
              '&::before': {
                display: 'none',
              },
            }}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography fontWeight={600}>{item.title}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography color="text.secondary" whiteSpace="pre-wrap">
                {item.subtitle}
              </Typography>
            </AccordionDetails>
          </Accordion>
        ))}
      </div>
    </div>
  );
};
