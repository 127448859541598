import React from 'react';
import { Affiliate } from 'components/pages/affiliate';
import { Header, Typography } from 'components';
import { ContainerStyled } from 'style';

export const AffiliatePage = () => {
  const headerContent = (
    <Typography variant="titleBold" color="accent">
      Affiliate Program
    </Typography>
  );
  return (
    <>
      <Header content={headerContent} />
      <ContainerStyled>
        <Affiliate />
      </ContainerStyled>
    </>
  );
};
