import { styled } from '@mui/material';

export const Wrapper = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '10px',
}));

export const Section = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  textAlign: 'center',
}));
