import React, { ReactElement, SyntheticEvent, useEffect, useState } from 'react';
import { AutocompleteRenderInputParams, Stack, TextField } from '@mui/material';
import { Control, Controller, FieldError } from 'react-hook-form';
import { AirportInfoModel } from 'models/airport.model';

import { AutocompleteStyled } from './styles';

type PropsType = {
  control: Control<any>;
  name: string;
  items: AirportInfoModel[];
  isLoading: boolean;
  inputLogo: ReactElement;
  placeholderText: string;
  onAirportSearch: (search: string) => void;
  error?: FieldError;
};

export const AutocompleteAirport = ({ control, name, items, isLoading, inputLogo, placeholderText, onAirportSearch, error }: PropsType) => {
  const [inputValue, setInputValue] = useState<string | null>('');
  const [open, setOpen] = useState<boolean>(false);

  const getOptionLabel = (option: AirportInfoModel) => {
    if (option.airportCode && option.name) {
      return `${option.airportCode} - ${option.city}`;
    }
    return '';
  };

  const onOpenHandler = () => {
    if (inputValue && inputValue?.length >= 1) {
      setOpen(true);
    }
  };

  const onChangeInputHandler = (_: SyntheticEvent, newInputValue: string) => {
    setInputValue(newInputValue);
    onAirportSearch(newInputValue);
  };

  useEffect(() => {
    const hasCharactersToSearch = inputValue && inputValue?.length > 1;
    if (!hasCharactersToSearch) {
      setOpen(false);
    }
  }, [inputValue]);

  return (
    <Stack>
      <Controller
        defaultValue={null}
        control={control}
        name={name}
        render={({ field: { value: autocompleteValue, onChange, ...rest } }) => {
          const autocomplete = `${autocompleteValue?.airportCode} - ${autocompleteValue?.city}`;
          const isOpenSuggestion = autocomplete !== inputValue && open;
          return (
            <AutocompleteStyled
              error={!!error}
              id={`airport-${name}`}
              open={isOpenSuggestion}
              noOptionsText={isLoading ? 'Loading...' : 'No Matching Airport'}
              onOpen={onOpenHandler}
              value={autocompleteValue}
              onChange={(_: React.SyntheticEvent, v: AirportInfoModel) => {
                onChange(v);
              }}
              inputValue={inputValue?.trim()}
              onInputChange={onChangeInputHandler}
              onClose={() => setOpen(false)}
              options={items}
              getOptionLabel={getOptionLabel}
              filterOptions={(options: AirportInfoModel) => options}
              isOptionEqualToValue={(option: AirportInfoModel, value: AirportInfoModel) => option.airportCode === value.airportCode}
              renderOption={(props: AutocompleteRenderInputParams, option: AirportInfoModel) => (
                <li {...props}>
                  {option.airportCode} - {option.name}, {option.city}
                </li>
              )}
              renderInput={(params: AutocompleteRenderInputParams) => (
                <TextField
                  {...params}
                  value={autocompleteValue ? `${autocompleteValue.airportCode} -${autocompleteValue.city}` : ''}
                  variant="filled"
                  placeholder={!autocompleteValue ? placeholderText : ''}
                  InputProps={{
                    ...params.InputProps,
                    startAdornment: inputLogo,
                  }}
                />
              )}
              {...rest}
            />
          );
        }}
      />
    </Stack>
  );
};
