import React from 'react';
import { Accordions, Typography } from 'components';
import { Section, Wrapper } from './styles';
import { Button } from '@mui/material';

export const Affiliate = () => {
  const onJoinClick = () => {
    window.open('https://forms.gle/mU5vWMxNQP4zt5t66', '_blank');
  };

  return (
    <Wrapper>
      <Section>
        <Typography variant="lineHeightLargeBold">Affiliate Program for Fasax Travel</Typography>
        <Typography variant="body">Earn commissions by sharing travel experiences with your network!</Typography>
      </Section>
      <Accordions
        defaultExpanded
        items={[
          {
            title: 'How It Works',
            subtitle:
              '1. Sign Up: Register and get your unique referral link.\n2. Promote & Share: Share our travel packages with your network.\n3. Earn Commission: Get paid for each booking made through your link.\n',
          },
          {
            title: 'Why Join?',
            subtitle: '- High commission rates\n- Exclusive travel deals\n- Easy tracking and reporting\n- Flexible payouts\n- Dedicated support',
          },
          {
            title: 'Program Details',
            subtitle: '- Commission Rate: $5 Per ticket\n- Payout Threshold: $5 per Month\n- Payment Cycle: First Monday of Every Month\n',
          },
          {
            title: 'FAQs',
            subtitle:
              '- How do I get paid? You’ll receive earnings via Zaad, Sahal or EVC Plus.\n- Can I promote on social media? Absolutely! Share on any platform.\n- Need help? Our team is here to assist you anytime.',
          },
        ]}
      />
      <Button onClick={onJoinClick} size="large" variant="contained">
        JOIN NOW
      </Button>
    </Wrapper>
  );
};
