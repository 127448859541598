import React from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js/pure';
import { CheckoutForm } from 'components/pages/entry/forms/CheckoutForm';
import { REACT_APP_STRIPE_PUBLISHABLE_KEY } from 'constants/stripe';
import { useAppSelector } from 'hooks/useAppSelector';
import { PaymentNetwork } from 'enum/enum';

import { MobilePaymentForm } from '../MobilePaymentForm';
import { PaymentOption } from 'models/payment.model';

interface PropTypes {
  accountNumber: string;
  updateAccountNumber: (accountNumber: string) => void;
  activePaymentTab: PaymentOption;
  onSubmit: () => void;
  disabled: boolean;
}

export const EnterPaymentForm = ({ activePaymentTab, onSubmit, updateAccountNumber, disabled, accountNumber }: PropTypes) => {
  const stripeSecretKey = useAppSelector((state) => state.payment.paymentBillingInfo?.stripeClientSecret);
  const stripOptions = { clientSecret: stripeSecretKey };
  const isCreditCard = activePaymentTab.network === PaymentNetwork.CREDIT_CARD;
  const stripe = loadStripe(REACT_APP_STRIPE_PUBLISHABLE_KEY);

  return (
    <>
      {isCreditCard ? (
        <Elements stripe={stripe} options={stripOptions}>
          <CheckoutForm stripeClientSecret={stripeSecretKey as string} />
        </Elements>
      ) : (
        <MobilePaymentForm
          accountNumber={accountNumber}
          activePaymentTab={activePaymentTab}
          onSubmitHandler={onSubmit}
          updateAccountNumber={updateAccountNumber}
          disabled={disabled}
        />
      )}
    </>
  );
};
