type RouteMap = {
  [key: string]: string;
};
export const routes: RouteMap = {
  home: '/',
  options: '/options/:origin-:destination/:departureDate/:returnDate?/:type',
  booking: '/booking',
  services: '/services',
  summary: '/summary',
  paymentOptions: '/payment-options',
  entry: '/entry',
  approval: '/approval',
  affiliate: '/affiliate',
  success: '/success',
  order: '/order',
  about: '/about',
  privacyTerms: '/privacy-terms',
  help: '/help',
  mytrips: '/mytrips',
  blog: '/blog',
  login: '/login',
  '404': '/404',
};
