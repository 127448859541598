import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';

import { CREATE_PAYMENT, CREATE_PAYMENT_data, CREATE_PAYMENT_vars } from 'api/mutations/createPayment';
import { FLIGHT_ORDER_DETAILS, FLIGHT_ORDER_DETAILS_data, FLIGHT_ORDER_DETAILS_vars } from 'api/queries/flightOrderDetails';
import { useAppSelector } from 'hooks/useAppSelector';
import { Currency, PaymentMode, PaymentNetwork, PaymentStatusEnum, RoutePage } from 'enum/enum';
import { failedPaymentStatuses, isSuccessPaymentStatus, pendingPaymentStatuses } from 'constants/payments';
import { routes } from 'routes';
import { allowPageAccess } from 'redux/slices/accessability/accessability-slice';
import { resetPaymentState, setPaymentBillingInfo, setPaymentNumber, setPaymentState } from 'redux/slices/payment/payment-slice';
import { resetFlightOrderState, setFlightOrderDetails } from 'redux/slices/flightOrder/flightOrder-slice';

const REQUEST_INTERVAL = 3000;
const REQUEST_INTERVAL_MPESA = 5000;
const INITIAL_SECONDS = 30;

export const usePaymentApproval = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const [seconds, setSeconds] = useState(INITIAL_SECONDS);
  const [hasError, setHasError] = useState(false);

  const { paymentTab, paymentNumber } = useAppSelector((state) => state.payment);
  const flightOrder = useAppSelector((state) => state.flightOrder);

  const priceCurrencyData = paymentTab?.network === PaymentNetwork.M_PESA_EXPRESS ? flightOrder?.priceOrderKes : flightOrder?.priceOrderUsd;
  const isMpesa = paymentTab?.paymentMode === PaymentMode.MPESA_EXPRESS;
  const isTimeOut = seconds === 0 && paymentTab?.paymentMode !== PaymentMode.MPESA_EXPRESS;

  const [createIntentPayment, { loading: createPaymentLoading }] = useMutation<CREATE_PAYMENT_data, CREATE_PAYMENT_vars>(CREATE_PAYMENT);

  const { loading: loadingFlightOrder, error } = useQuery<FLIGHT_ORDER_DETAILS_data, FLIGHT_ORDER_DETAILS_vars>(FLIGHT_ORDER_DETAILS, {
    variables: {
      flightOrderId: flightOrder.flightOrderId as string,
    },
    pollInterval: isMpesa ? REQUEST_INTERVAL_MPESA : REQUEST_INTERVAL,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'no-cache',
    skip: hasError || isTimeOut,

    onCompleted: (data) => {
      dispatch(setFlightOrderDetails(data.flightOrderDetails));
      const paymentStatus = data?.flightOrderDetails.orderPaymentStatus!;
      const associatedPayments = data?.flightOrderDetails.associatedPayments;
      const foundFailedPayment = associatedPayments?.find((p) => failedPaymentStatuses.includes(p.paymentState))?.paymentState;

      if (foundFailedPayment) {
        setHasError(true);
      }

      dispatch(setPaymentState(paymentStatus));
      if (isSuccessPaymentStatus(paymentStatus)) {
        dispatch(allowPageAccess(RoutePage.SUCCESS));
        history.push(routes.success, { access: true });
      }
      if (pendingPaymentStatuses.includes(paymentStatus)) {
        setLoading(true);
      }
    },
    onError: () => {
      setLoading(false);
    },
  });
  const changePaymentNumber = () => {
    dispatch(resetPaymentState());
    history.push(`${routes.entry}/${paymentTab?.network}`.toLowerCase());
  };

  const createNewPayment = async () => {
    setHasError(false);
    const paymentData = await createIntentPayment({
      variables: {
        flightOrderId: flightOrder.flightOrderId as string,
        valueInput: {
          amount: priceCurrencyData?.amount || '',
          currency: priceCurrencyData?.currency ?? Currency.USD,
        },
        paymentMode: paymentTab!.paymentMode,
        ...(paymentTab?.paymentMode !== PaymentMode.STRIPE && { accountNumber: paymentNumber }),
      },
      onCompleted: () => {
        dispatch(resetFlightOrderState());
        dispatch(setPaymentState(PaymentStatusEnum.OPEN));
        setSeconds(INITIAL_SECONDS);
      },
    });
    dispatch(setPaymentNumber(paymentNumber));
    dispatch(setPaymentBillingInfo(paymentData?.data?.createPayment));
  };

  return {
    isFailed: hasError || !!error,
    isLoading: loading || loadingFlightOrder || createPaymentLoading,
    isTimeOut,
    seconds,
    setSeconds,
    changePaymentNumber,
    retryRequestHandler: createNewPayment,
  };
};
